<template>
  <div class="applyWithdrawal">
    <van-nav-bar title="申请提现" @click-left="leftReturn" left-arrow></van-nav-bar>
    <div class="bankCard">
      <div class="bankCard_message">
        <span class="bankCard_Withdrawal">提现到银行卡</span>
        <span class="bankCard_number">  {{ openingBank }}&nbsp;({{ bankCode.split(' ').join('').substr(-4) }})</span>
        <!--                <span class="bankCard_change">-->
        <!--                    更换-->
        <!--                    <img class="bankCard_img" src="../../../assets/images/youjiantou.png"/>-->
        <!--                </span>-->
      </div>
    </div>
    <div class="input">
      <div class="withdrawMoney">提现金额</div>
      <div class="withdrawMoney_input">
        <img class="withdrawMoney_img" src="../../../assets/images/money2x.png"/>
        <!--                <el-input-number class="withdrawMoney_inputs" v-model="value" :min="0" :max="100" :controls="false"></el-input-number>-->
        <input type="number" min="0" class="withdrawMoney_inputs" @click="shows()" v-model="value"/>
        <!--                键盘组件-->
        <!--                <van-number-keyboard-->
        <!--                        :show="show"-->
        <!--                        :maxlength="6"-->
        <!--                        @blur="show = false"-->
        <!--                />-->
        <div>
          <van-button class="withdrawMoney_button" @click="save">提现</van-button>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {NavBar, Icon, Button, NumberKeyboard} from 'vant';
import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
import {queryPersonDetails, withdrawalApplicationSave} from "../../../getData/getData"

export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [NumberKeyboard.name]: NumberKeyboard
  },
  data() {
    return {
      show: false,
      value: '',
      openingBank: '',
      bankCode: '',
    }
  },

  name: "applyWithdrawal",
  mounted() {
    this.init()
  },
  methods: {
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    shows() {
      this.show = true;
    },
    init() {
      let that = this
      let initData = {}
      initData.user_id = globaluserId()
      // debugger
      queryPersonDetails(initData).then(function (response) {
        console.log(response)
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data)
          that.openingBank = response.data.data.openingBank
          that.bankCode = response.data.data.bankCode
        })
      })
    },
    save() {
      let that = this

      //判断提现金额是否大于余额
      if (0 !== parseInt(that.value) && parseInt(this.$route.params.balance) < parseInt(that.value)) {
        responseUtil.alertMsg(that, "提现金额不能大于余额！");
      } else {
        let saveData = {}
        saveData.user_id = globaluserId()
        saveData.money = that.value
        if (that.value == '' || that.value <= 0) {
          responseUtil.alertMsg(this, "请输入正确金额！");
          that.value = ''
          return
        }
        withdrawalApplicationSave(saveData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            if (200 == response.status) {
              that.value = ''
              responseUtil.alertMsg(that, "提现申请成功")
              that.$router.go(-1)
            }

          })
        })
      }
    },

  },
}
</script>

<style scoped>
.bankCard {
  width: 345px;
  height: 60px;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-left: 15px;
  margin-top: 20px;
}

.bankCard_message {
  height: 20px;
  line-height: 20px;
  padding: 15px 0px 20px 20px;
  /*font-weight: bolder;*/

}

.bankCard_Withdrawal {
  height: 20px;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
}

.bankCard_number {
  width: 115px;
  height: 20px;
  color: rgba(34, 34, 34, 1);
  font-size: 14px;
  text-align: left;
  font-family: PingFangSC-Regular;
  padding-left: 10px;
}

.bankCard_change {
  height: 20px;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  float: right;
  margin: 7px 10px 0 0;
}

.bankCard_img {
  width: 5px;
  height: 8px;
  padding-left: 5px;
  padding-bottom: 2px;
}

.input {
  margin: 15px 15px 0px 15px;
  width: 345px;
  height: 255px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}

.withdrawMoney {
  padding: 44px 0px 0px 20px;
  width: 90px;
  height: 20px;
  color: rgba(34, 34, 34, 1);
  font-size: 14px;
  text-align: left;
  font-family: PingFangSC-Regular;
}

.withdrawMoney_img {
  width: 16px;
  height: 28.33px;
  padding: 35px 0px 0px 20px;
}

.withdrawMoney_input {
  display: flex;
}

.withdrawMoney_inputs {
  width: 200px;
  height: 61px;
  border: none;
  caret-color: #FF5D3B;
  font-size: 45px;
  padding-top: 15px;
  padding-left: 15px;
  font-family: Oswald-Regular;
}

.withdrawMoney_button {
  margin: 100px 0px 0px -212px;
  width: 265px;
  height: 45px;
  border-radius: 8px;
  background-color: #FFFFFF;
}

</style>
